/* jshint esversion: 6 */

import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import { createRequest } from './helpers/createRequest';
import { htmlToElement } from './helpers/dom';

export default class extends Controller {
  static targets = ["form"]

  enrollStudent(event) {
    const { userId } = event.target.parentNode.dataset;
    const formData = new FormData();
    formData.append('user_id', userId);

    fetch(createRequest(`/projects/${this.data.get('project-id')}/add_student_to_project`, 'post', formData))
      .then((response) => response.text())
      .then((html) => {
        const el = event.target.parentNode.parentNode;
        el.parentNode.replaceChild(htmlToElement(html), el);
      });
  }

  unenrollStudent(event) {
    const { userId } = event.target.parentNode.dataset;
    const formData = new FormData();
    formData.append('user_id', userId);

    fetch(createRequest(`/projects/${this.data.get('project-id')}/remove_student_from_project`, 'post', formData))
      .then((response) => response.text())
      .then((html) => {
        const el = event.target.parentNode.parentNode;
        el.parentNode.replaceChild(htmlToElement(html), el);
      });
  }

  paste(event) {
    event.preventDefault();
    event.stopPropagation();

    window.notice('Pasting folder. Please wait..', 5 * 60);

    fetch(event.target.closest('a').href).then((response) => {
      Turbolinks.visit(window.location.toString(), { action: 'replace' });
    });
  }

  onSubmit(event) {
    // hijacking the form submit just to set ordinals
    event.preventDefault();
    event.stopPropagation();

    const tabsController = this.application.getControllerForElementAndIdentifier(document.querySelector('#edit-tabs'), 'studios--edit-tabs');

    if (tabsController !== null) {
      const tabForms = tabsController.tabFormTargets;

      tabsController.tabNameTargets.forEach((el, i) => {
        const { tabId } = el.dataset;
        tabForms.find((el) => el.dataset.tabId === tabId).querySelector('.ordinal').value = i;
      });
    }

    this.formTarget.submit();
  }
}
