/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import { createRequest } from '../helpers/createRequest';
import SlimSelect from 'slim-select';

export default class extends Controller {

  static targets = ["form"]

  connect() {
    window.activateSlimSelect();
    new SlimSelect({
      select: '#collab-studio-select select',
      placeholder: 'Search For A Studio',
      searchingText: 'Searching...', // Optional - Will show during ajax request
      showContent: 'down',
      ajax: function (search, callback) {
        // Check search value. If you dont like it callback(false) or callback('Message String')
        if (search.length < 2) {
          callback('Needs at least 2 characters')
          return
        }

        // Perform your own ajax request here
        fetch(`/studios/${window.studioId}/autocomplete_collaboration_studio?q=${search}`)
          .then(function (response) {
            return response.json()
          })
          .then(function (json) {
            let data = []
            for (let i = 0; i < json.length; i++) {
              data.push({text: json[i].name_with_school, value: json[i].id})
            }
            // Upon successful fetch send data to callback function.
            // Be sure to send data back in the proper format.
            // Refer to the method setData for examples of proper format.
            callback(data)
          })
          .catch(function(error) {
            // If any erros happened send false back through the callback
            callback(false)
          })
      }
    })
  }

  onSubmit(event) {
    if (this.data.get('section') !== 'true') {
      // hijacking the form submit just to set ordinals
      event.preventDefault();
      event.stopPropagation();

      const tabsController = this.application.getControllerForElementAndIdentifier(document.querySelector("#edit-tabs"), "studios--edit-tabs")

      if (tabsController !== null) {
        const tabForms = tabsController.tabFormTargets;

        tabsController.tabNameTargets.forEach((el, i) => {
          let tabId = el.dataset.tabId;
          tabForms.find(el => el.dataset.tabId == tabId).querySelector('.ordinal').value = i;
        });
      }

      this.formTarget.querySelectorAll('.hidden.child_tabs_for_assignments input').forEach(input => input.setAttribute("disabled", ""))
      this.formTarget.submit();
    }
  }

  handleTermChange(event) {
    const termId = event.target.value;

    fetch(createRequest(`/terms/${termId}/sessions_dropdown_options`, 'get'))
      .then((response) => response.text())
      .then((html) => {
        const sessions_dropdown = document.querySelector('.sessions');
        sessions_dropdown.innerHTML = html;
        sessions_dropdown.parentNode.querySelector('.select-selected').remove();
        window.activateSlimSelect();
      });

  }

  handleCloneTabSelectionChanged(event) {
    let parentTab = event.currentTarget
    let parentTabId = parentTab.value
    let parentTabChecked = parentTab.checked
    let checkboxes = document.querySelectorAll(`input[data-parent-tab-id='${parentTabId}']`)

    for (const checkbox of checkboxes) {
      if (parentTabChecked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    }
  }

  deleteConfirm(event) {
    if(confirm('Delete this studio?')) {
      if(confirm('Are you sure?')) {
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  chooseWeekDays(event) {
    event.preventDefault();
    document.getElementById('studio_extras_working_day_mon').checked = true;
    document.getElementById('studio_extras_working_day_tue').checked = true;
    document.getElementById('studio_extras_working_day_wed').checked = true;
    document.getElementById('studio_extras_working_day_thu').checked = true;
    document.getElementById('studio_extras_working_day_fri').checked = true;
    document.getElementById('studio_extras_working_day_sat').checked = false;
    document.getElementById('studio_extras_working_day_sun').checked = false;

  }

}
