import ApplicationController from '../editor/application_controller';
import { createRequest } from '../helpers/createRequest';
import { htmlToElement, insertAfter } from '../helpers/dom';

export default class extends ApplicationController {
  deleteTab(event) {
    if (window.confirm('Are you sure?')) {
      const link = event.target;
      const { tabId, studioId } = link.dataset;
      fetch(createRequest(`/x1/studios/${studioId}/section/${tabId}`, 'DELETE'))
        .then(() => {
          link.closest('li').remove();
        })
        .catch((error) => {
          console.log(error);
        });
      this.hideDropdowns();
    }
  }

  addAssignment(event) {
    const { tabId, studioId } = event.target.dataset;
    const input = document.querySelector('#new-assignment').content.cloneNode(true);
    const assignments = document.querySelector(`#assignments-for-${tabId}`);
    const lastAssignment = assignments.querySelector('li:last-child');
    if (lastAssignment) {
      insertAfter(input, lastAssignment);
    } else {
      assignments.appendChild(input);
    }
    const newInput = assignments.querySelector('input');
    newInput.dataset.tabId = tabId;
    newInput.dataset.studioId = studioId;
    newInput.focus();
    this.hideDropdowns();
  }

  createAssignment(event) {
    const { studioId, tabId } = event.target.dataset;
    const formData = new FormData();
    formData.append('name', event.target.value);
    fetch(createRequest(`/x1/studios/${studioId}/section/${tabId}/assignments`, 'POST', formData))
      .then((response) => response.text())
      .then((html) => event.target.parentElement.replaceWith(htmlToElement(html)));
  }

  cancelAddAssignment(event) {
    event.target.closest('div').remove();
  }

  deleteAssignment(event) {
    if (window.confirm('Are you sure?')) {
      const link = event.target;
      const { assignmentId, studioId } = link.dataset;
      fetch(createRequest(`/x1/studios/${studioId}/assignments/${assignmentId}`, 'DELETE'))
        .then(() => {
          link.closest('li').remove();
        })
        .catch((error) => {
          console.log(error);
        });
      this.hideDropdowns();
    }
  }

  editAssignment(event) {
    const { assignmentId, studioId } = event.target.dataset;
    const input = document.querySelector('#edit-assignment').content.cloneNode(true);
    const assignment = document.querySelector(`#assignment-${assignmentId}`);
    const title = assignment.querySelector('a').innerText;
    assignment.firstChild.classList.add('hidden');
    assignment.appendChild(input);
    const newInput = assignment.querySelector('input');
    newInput.dataset.assignmentId = assignmentId;
    newInput.dataset.studioId = studioId;
    newInput.value = title;
    newInput.focus();
    this.hideDropdowns();
  }

  cancelEditAssignment(event) {
    const cancelButton = event.currentTarget;
    cancelButton.closest('li').querySelector('a').classList.remove('hidden');
    cancelButton.parentElement.remove();
  }

  updateAssignment(event) {
    const { assignmentId, studioId } = event.target.dataset;
    const formData = new FormData();
    formData.append('name', event.target.value);
    fetch(createRequest(`/x1/studios/${studioId}/assignments/${assignmentId}`, 'POST', formData))
      .then((response) => response.text())
      .then((html) => {
        const link = event.target.closest('li').firstChild;
        link.innerText = event.target.value;
        link.classList.remove('hidden');
        event.target.parentElement.remove();
        this.hideDropdowns();
      });
  }

  toggleHidden(event) {
    const { studioId, tabId } = event.target.dataset;
    event.target.closest('li').classList.toggle('text-opacity-50');
    fetch(createRequest(`/x1/studios/${studioId}/section/${tabId}/toggle`, 'POST'))
      .catch((error) => {
        console.log(error);
      });
    this.hideDropdowns();
  }

  hideDropdowns() {
    document.querySelectorAll('[data-target="x1--dropdown.menu"]').forEach((el) => el.classList.add('hidden'));
  }
}
