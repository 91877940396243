/* global FroalaEditor */
import { Controller } from 'stimulus';
import InfiniteScroll from 'infinite-scroll';
import Rails from 'rails-ujs';
import Sortable from 'sortablejs';

// TODO: Move media browser codes

export default class extends Controller {
  initialize() {
    // Load all unloaded images
    console.log('loading images');
    const unloadedImages = document.querySelectorAll('.unloaded-image');
    unloadedImages.forEach((image) => {
      const tempImage = new Image();
      tempImage.src = image.getAttribute('data-background-image');
      image.style.backgroundImage = `url("${image.getAttribute('data-background-image')}")`;
      image.classList.remove('unloaded-image');
    });

    const debounce = (func, wait, immediate) => {
      var timeout;
      return () => {
        const context = this, args = arguments;
        const later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    window.addEventListener('resize', debounce(() => window.zoomPresentation(), 200, false), false);
    window.addEventListener('orientationchange', () => window.zoomPresentation(), false);

    window.zoomPresentation();
    window.posts_are_collapsed = false;
    this.autoSaveInterval = null;
    const trackFullScreenChange = function () {
      if (document.fullscreenElement
        || document.webkitFullscreenElement
        || document.mozFullScreenElement) {
        // Skip
      } else {
        const elem = document.querySelector('.fullscreen');
        if (elem) elem.classList.remove('fullscreen');
        elem.querySelector('.presentation-canvas').style.zoom = 1;
      }
    };

    document.addEventListener('fullscreenchange', () => {
      trackFullScreenChange();
    });

    /* Firefox */
    document.addEventListener('mozfullscreenchange', () => {
      trackFullScreenChange();
    });

    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', () => {
      trackFullScreenChange();
    });

    /* IE / Edge */
    document.addEventListener('msfullscreenchange', () => {
      trackFullScreenChange();
    });

    if (document.querySelector('.tab-container')) {
      const tabContainer = document.querySelector('.tab-container');
      if (tabContainer.getAttribute('data-show-compressed-view') === 'true' && !tabContainer.classList.contains('compressed')) {
        const postImages = document.querySelectorAll('.post img');
        Promise.all(Array.from(postImages).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
            this.compressToggle();
            tabContainer.classList.add('compressed');
            window.posts_are_collapsed = true;
        });

      }
    }

    if (document.querySelector('.infinite-scroll') && document.querySelector('.pagination__next')) {
      const infScroll = new InfiniteScroll('.infinite-scroll .posts-container', {
        // options
        path: '.pagination__next',
        append: '.post',
        history: false,
        scrollThreshold: 600,
      });

      infScroll.on('append', (response, path, items) => {
        items.forEach((element) => {
          if (window.posts_are_collapsed) {
            const compressableArea = element.querySelector('.post-compressable-area');
            if (compressableArea) {
              const padder = element.querySelector('.post > .post-padder');
              padder.classList.add('post-vertical-concise-padded');
              padder.classList.remove('post-vertical-padded');
              compressableArea.dataset.normalHeight = compressableArea.offsetHeight;
              window.setTimeout(() => {
                compressableArea.style.display = 'none';
                compressableArea.style.height = 0;
                compressableArea.dataset.compressed = true;
              }, 5);
            }
          }

          // Safari hack to display videos after infinite scroll loads new items
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          const videoElement = element.querySelector('video');
          if (isSafari && videoElement !== null) {
            const cloneVideoElement = videoElement.cloneNode(true);
            videoElement.replaceWith(cloneVideoElement);
          }
        });
      });

      window.infScroll = infScroll;
    }
  }

  disconnect() {
    if (window.infScroll) {
      window.infScroll.destroy();
    }
  }

  openNewPostForm(event) {
    if (!document.getElementById('new_post')) {
      const path = this.data.get('create-post-path');
      Rails.ajax({
        url: path,
        type: 'GET',
      });
    }
    document.querySelectorAll('.posts-actions-item').forEach((element) => {
      element.classList.remove('sm:block');
      element.classList.add('sm:hidden');
    });
  }

  // autoSave() {
  //   const newPostElement = document.getElementById('new_post');
  //   if (newPostElement) {
  //     const formElement = newPostElement.querySelector('form.edit_post');
  //     const formData = new FormData(formElement);
  //     formData.set('post[saving_mode]', 'save');
  //     const { autoSaveUrl } = formElement.dataset;
  //     Rails.ajax({
  //       url: autoSaveUrl,
  //       type: 'PATCH',
  //       data: formData,
  //       success: (resp, status, xhr) => {
  //         if (resp && !resp.success && this.autoSaveInterval) {
  //           clearInterval(this.autoSaveInterval);
  //         }
  //       },
  //     });
  //   }
  // }

  leavingPage() {
    const leavingPageMessage = 'Do you want to leave?';
    if (!window.confirm(leavingPageMessage)) {
      event.preventDefault();
    }
  }

  saveForm(event) {
    event.preventDefault();
    const saveButton = event.target;
    saveButton.innerHTML = 'SAVING...';
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    const formElement = event.target.closest('form');
    // Adds saving mode; save, publish
    const formData = new FormData(formElement);
    formData.set('post[saving_mode]', 'save');
    Rails.ajax({
      url: formElement.action,
      type: 'POST',
      data: formData,
    });
  }

  cancelForm(event) {
    event.preventDefault();
    const cancelButton = event.target;
    const { cancelPostUrl } = cancelButton.dataset;
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    const formElement = event.target.closest('form');
    const formData = new FormData(formElement);
    const existingPost = formElement.querySelector('.existing_post').value == 'true';

    Rails.ajax({
      url: `${cancelPostUrl}?existing_post=${existingPost}`,
      type: 'GET',
    });
  }

  closeNewPostForm(event) {
    event.preventDefault();

    const formFields = document.querySelectorAll('.field');
    let i;
    for (i = 0; i < formFields.length; i++) {
      formFields[i].classList.remove('field_with_errors');
    }

    document.getElementById('new-post-container').innerHTML = '';
    document.getElementById('new_post_title').value = '';

    document.querySelectorAll('.posts-actions-item').forEach((element) => {
      element.classList.add('sm:block');
      element.classList.remove('sm:hidden');
    });
  }

  closeEditPostForm(event) {
    event.preventDefault();

    document.getElementById('new-post-container').innerHTML = '';
    document.getElementById('new_post_title').value = '';
  }

  autoUpdateTargetInput(event) {
    const targetId = event.target.getAttribute('data-target-input');
    document.getElementById(targetId).value = event.target.value;
  }

  openSlideEditor() {
    event.preventDefault();
    const active_slides = document.querySelectorAll('.slide:not(.hidden)');
    if (active_slides.length > 0) {
      const active_slide = active_slides[0];
      const postId = active_slide.getAttribute('data-post-id');
      const slideId = active_slide.getAttribute('data-slide-id');
      window.location = `/posts/${postId}/slide_editor?slide_id=${slideId}`;
    }
  }

  showThumbs(event) {
    event.preventDefault();
    event.stopPropagation();

    const presentationId = event.target.closest('a').getAttribute('data-presentation-id');

    Rails.ajax({
      url: `/presentations/${presentationId}/thumbs`,
      type: 'GET',
    });
  }

  toggleFullScreen(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelector('.fullscreen')) {
      this.closeFullscreen(event);
    } else {
      this.openFullScreen(event);
    }
  }

  openFullScreen(event) {
    const presentationId = event.target.closest('.present-button-container').getAttribute('data-presentation-id');
    event.target.closest('.present-button-container').querySelector('.present-link').innerHTML = 'Close';
    const elem = document.getElementById(`presentation-container-${presentationId}`);

    let screenWidth = parseFloat(screen.width);
    let presentationWidth = window.slideWidth || parseFloat(event.target.closest('.presentation-container').querySelector('.presentation-canvas').offsetWidth);


    let innerWidth = parseFloat(window.innerWidth);
    let outerwidth = parseFloat(window.outerWidth);

    // If we are not in an iframe
    if (innerWidth !== outerwidth && window === window.parent) {
      presentationWidth *= outerwidth / innerWidth;
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    elem.classList.add('fullscreen');
    elem.querySelector('.presentation-canvas').style.zoom = screenWidth / presentationWidth;
  }

  closeFullscreen(event) {
    const presentationId = event.target.closest('a').getAttribute('data-presentation-id');
    event.target.closest('a').innerHTML = 'Present';
    const elem = document.getElementById(`presentation-container-${presentationId}`);

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }

    elem.classList.remove('fullscreen');
    elem.querySelector('.presentation-canvas').style.zoom = 1;
  }

  titleClicked(event) {
    if (window.posts_are_collapsed) {
      event.preventDefault();
      const currentCompressableItem = event.currentTarget.closest('.post').querySelector('.post-compressable-area');
      const { compressed } = currentCompressableItem.dataset;
      if (compressed === 'true') {
        this.animateSlideDown(currentCompressableItem);
        currentCompressableItem.dataset.compressed = false;
      } else {
        this.animateSlideUp(currentCompressableItem);
        currentCompressableItem.dataset.compressed = true;
      }
    }
  }

  compressToggle(event) {
    if (event) event.preventDefault();
    // Set global post collaped status for pagination
    if (window.posts_are_collapsed === undefined) {
      window.posts_are_collapsed = false;
    }

    if (window.posts_are_collapsed) {
      window.posts_are_collapsed = false;
      document.querySelectorAll('.post > .post-padder').forEach((element) => {
        element.classList.add('post-vertical-padded');
        element.classList.remove('post-vertical-concise-padded');
      });
    } else {
      window.posts_are_collapsed = true;
      document.querySelectorAll('.post > .post-padder').forEach((element) => {
        element.classList.remove('post-vertical-padded');
        element.classList.add('post-vertical-concise-padded');
      });
    }
    // Get button to change its name
    document.querySelectorAll('.post-compressable-area').forEach((element) => {
      if (!window.posts_are_collapsed) {
        this.animateSlideDown(element);
        element.dataset.compressed = false;
      } else {
        this.animateSlideUp(element);
        element.dataset.compressed = true;
      }
    });
  }

  animateSlideUp(target) {
    const duration = 500;
    target.style.height = `${target.offsetHeight}px`;
    if (target.dataset.normalHeight === undefined) {
      target.dataset.normalHeight = target.offsetHeight;
    }
    target.style.opacity = 1;
    window.setTimeout(() => {
      target.style.transitionProperty = 'height, opacity';
      target.style.transitionDuration = `${duration}ms`;
      target.style.height = 0;
      target.style.opacity = 0;
      target.style.overflow = 'hidden';
      window.setTimeout(() => {
        target.style.display = 'none';
      }, duration);
    }, 10);
  }

  animateSlideDown(target) {
    const duration = 500;
    target.style.removeProperty('display');
    let { display } = window.getComputedStyle(target);
    if (display === 'none') {
      display = 'block';
    }
    window.setTimeout(() => {
      target.style.display = display;
      const height = target.dataset.normalHeight;
      target.style.height = 0;
      target.style.opacity = 0;
      target.style.overflow = 'hidden';
      // Sliding
      target.style.transitionProperty = 'height, opacity';
      target.style.transitionDuration = `${duration}ms`;
      if (height > 0) {
        target.style.height = `${height}px`;
      } else {
        target.style.height = 'inherit';
      }
      target.style.opacity = 1;
    }, 10);
  }

  arrange(event) {
    event.preventDefault();

    try {
      if (window.infScroll) {
        window.infScroll.destroy();
      }
    } catch (err) {
      console.log('Infinite scroll not found');
    }

    const paginationLink = new URL(window.location.href);
    const params = new URLSearchParams(paginationLink.search.slice(1));
    params.delete('page');
    params.set('show_for', 'sorting');
    const requestLink = `${paginationLink.origin}${paginationLink.pathname}?${params.toString()}`;

    const elPostsContainer = document.querySelector('.posts-container');
    elPostsContainer.innerHTML = "<div class='text-center mt-5'>Loading...</div>";

    // create wrapper form
    const formWrapper = document.createElement('form');
    formWrapper.acceptCharset = 'UTF-8';
    formWrapper.id = 'formPostSorting';
    formWrapper.action = event.currentTarget.dataset.setPositionUrl;
    formWrapper.dataset.remote = 'true';
    formWrapper.method = 'post';
    elPostsContainer.parentNode.insertBefore(formWrapper, elPostsContainer);
    formWrapper.appendChild(elPostsContainer);

    Rails.ajax({
      url: requestLink,
      type: 'GET',
      success: (resp, status, xhr) => {
        if (resp) {
          document.querySelector('.posts-container').innerHTML = resp.querySelector('.posts-container').innerHTML;
        }
        window.setTimeout(() => {
          document.querySelectorAll('.post-compressable-area').forEach((element) => {
            this.animateSlideUp(element);
          });
          document.querySelectorAll('.posts-container .post').forEach((element) => {
            element.insertAdjacentHTML('beforeend', `<input type="hidden" name="post[][id]" value="${element.dataset.id}" /><input type="hidden" class="post-position" name="post[][position]" value="${element.dataset.position}" />`);
          });
          const sortable = document.querySelector('.posts-container');
          var el = Sortable.create(sortable, {
            animation: 150,
            draggable: 'div.post',
            forceFallback: true,
            onEnd(event) {
              el.toArray().forEach((item, index) => {
                document.querySelector(`[data-id="${item}"] .post-position`).value = index + 1;
              });
              Rails.fire(document.querySelector('#formPostSorting'), 'submit');
            },
          });
          elPostsContainer.insertAdjacentHTML('beforeend', '<div class="container mx-auto text-center mb-20"><a class="btn uppercase" onclick="location.reload()">Done Arranging</a></div>');
        }, 10);
      },
    });
  }
}
